<template>
  <div v-if="!user.email_verified_at" class="card">
    <div class="card-body">
      <AccountUnverified/>
    </div>
  </div>
  <div class="SettingsLayout" v-else-if="settings && dataFetched">
    <div class="description" style="margin-bottom: 17px; margin-top: -25px">
      The 'Monitor Settings' feature allows you to apply settings to all of your current and future monitors.
      Any changes you make will be applied to all existing and subsequent monitors.
    </div>

      <div class="row">
        <div class="col-md-8">
            <tabs>

              <tab title="General Settings" description="URL, Name and Request Frequency">
                <div class="card">
                  <div class="card-body settings-card">
                    <monitors-settings-general
                      :settings="settings"
                    />
                  </div>
                </div>
              </tab>

              <tab title="Request Settings" description="Method and Request Body / Headers">
                <div class="card">
                  <div class="card-body settings-card">
                    <monitors-settings-request
                      :settings="settings"
                      @update:request_type="settings.request.type = $event.type"
                    />
                  </div>
                </div>
              </tab>

              <tab title="Response Settings" description="Response Text and Headers"
                   :className="settings.request.type !== 'http' ? 'd-none' : ''"

              >
                <div class="card">
                  <div class="card-body settings-card">
                    <monitors-settings-response :settings="settings"
                                                @update:response="setResponse($event)"/>
                  </div>
                </div>
              </tab>

            </tabs>
        </div>
      </div>

  </div>
</template>
<script>
import Tabs from '@/views/monitors/_id/settings/tabs.vue'
import Tab from '@/views/monitors/_id/settings/tab.vue'
import profileApi from '@/api/profileApi'
import MonitorsSettingsGeneral from '@/components/Account/MonitorsSettings/MonitorsSettingsGeneral.vue'
import MonitorsSettingsRequest from '@/components/Account/MonitorsSettings/MonitorsSettingsRequest.vue'
import MonitorsSettingsResponse from '@/components/Account/MonitorsSettings/MonitorsSettingsResponse.vue'

export default {
  components: { MonitorsSettingsResponse, MonitorsSettingsRequest, MonitorsSettingsGeneral, Tab, Tabs },

  data () {
    return {
      dataFetched: false,
      settings: {
        uptime_check_frequency: 1800,
        offline_notification_delay: null,
        ssl_check: 1,
        redirect_check: 1,
        request:
          {
            method: 'get',
            body_type: null,
            body_raw: null,
            body_json: null,
            body_form_params: null,
            headers: [],
            timeout: 10,
            type: 'http',
            tcp_ports: null
          },

        response: {
          body: null,
          headers: [],
          code: null,
          is_negative: null,
          body_case_insensitive: null
        }
      }
    }
  },

  created () {
    if (this.user.email_verified_at) {
      this.loadSettings()
    }
  },

  methods: {
    async loadSettings () {
      const response = await profileApi.getMonitorsGeneralSettings()
      if (response.data) {
        this.settings = response.data
        if (!this.settings.request.type) {
          this.settings.request.type = 'http'
        }
      }
      this.dataFetched = true
    },

    setRequestBody (val) {
      this.settings.request.body_form_params = val.formParams
      this.settings.request.body_json = val.json
      this.settings.request.body_raw = val.body
      this.settings.request.body_type = val.type
    },

    setResponse (val) {
      const clone = Object.assign({}, val)
      this.settings.response = { ...this.settings.response, ...clone }
    }
  },
  computed: {
    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
.SettingsLayout {
  .toolbar {
    position: sticky;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    padding: 15px;
    backdrop-filter: blur(3px);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .settings-card {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
